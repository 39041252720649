body {
	font-family: Open Sans, sans-serif;
	background-color: white;
	color: var(--text-color);
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
	// line-height: 1.15;
}

.app-nav {
	height: 100px;
}

.login-modal .modal-content {
	background-color: var(--success);
	border-radius: 20px;
}

.carousel-root {
	.carousel.carousel-slider {
		overflow-y: visible;
		overflow-x: clip;
	}

	.carousel {
		.slider-wrapper {
			overflow-y: visible;
			overflow-x: clip;
		}
	}

	.tippy-box {
		border: none;
		background-color: transparent;
	}
}

.course-detail-page {
	.course-block-buy {
		padding-top: 100px;
		width: 80%;
	}
}

body .react-confirm-alert-overlay {
	z-index: 1051 !important;
}

.login-modal {
	z-index: 1051 !important;
}

.contact-form-wrapper {
	//border border-orange mt-3 rounded-10px py-5 px-5
	border: 1px solid $color-orange;
	border-radius: 10px;
	padding: 2rem 2rem;
}

.contact-item {
	margin-left: 3rem;
	margin-right: 3rem;
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
	.contact-item {
		margin-left: 2rem;
		margin-right: 2rem;
	}
}

// Large devices (desktops, 992px and up)
@media (max-width: 992px) {
}

// Medium devices (tablets, 768px and up)
@media (max-width: 768px) {
	//md break point
	.contact-form-wrapper {
		border: none;
		padding: 0 0;
		margin-top: 2rem;
	}

	.contact-item {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) {
	//md break point
	.contact-form-wrapper {
		border: none;
		padding: 0 0;
		margin-top: 2rem;
	}

	.contact-item {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}
